import styleToCss from "style-object-to-css-string"
import styled, { css } from "styled-components"
import { borderRadius } from "../../../theme/globalStyles"

type keys = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

const materialToCssHeaders = (key: keys) => css`
  ${({ theme }) => styleToCss(theme.typography[key])};
  color: ${({ theme }) => theme.palette.primary.dark};

  @media (min-width: 600px) {
    ${({ theme }) =>
      theme.typography[key]["@media (min-width:600px)"] &&
      styleToCss(theme.typography[key]["@media (min-width:600px)"])};
  }
  @media (min-width: 960px) {
    ${({ theme }) =>
      theme.typography[key]["@media (min-width:960px)"] &&
      styleToCss(theme.typography[key]["@media (min-width:960px)"])};
  }
  @media (min-width: 1280px) {
    ${({ theme }) =>
      theme.typography[key]["@media (min-width:1280px)"] &&
      styleToCss(theme.typography[key]["@media (min-width:1280px)"])};
  }
`

const WpContent = styled.div`
  ol {
    li {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }
  ul {
    li {
      margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
    }
  }
  ul,
  ol {
    margin-left: ${({ theme }) => theme.spacing(4)}px;
    li {
      padding-right: ${({ theme }) => theme.spacing(8)}px;
      & :last-of-type {
        margin-bottom: 0;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
      li {
        padding-right: ${({ theme }) => theme.spacing(1)}px;
      }
    }
  }
  table {
    border: none;
  }
  td {
    padding: ${({ theme }) => theme.spacing(2)}px;
    border: none;
  }
  @media (max-width: 835px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      width: 100% !important;

      .inline-gatsby-image-wrapper {
        float: none;
        display: block;
        margin: 0 auto;
      }
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
    }
  }
  /* 
  * =Material UI
  */
  h1 {
    ${materialToCssHeaders("h1")};
  }
  h2 {
    ${materialToCssHeaders("h2")};
  }
  h3 {
    ${materialToCssHeaders("h3")};
  }
  h4 {
    ${materialToCssHeaders("h4")};
  }
  h5 {
    ${materialToCssHeaders("h5")};
  }
  h6 {
    ${materialToCssHeaders("h6")};
  }
  /*
  * =Custom WordPress Mui Mix
  */
  a {
    color: ${({ theme }) => theme.palette.primary.dark};
    transition: all ${({ theme }) => theme.transitions.duration.short}ms
      ease-in-out;
  }
  a:hover,
  a:focus {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }

  blockquote {
    font-family: Qwigley;
    font-size: 2.6rem;
    margin: ${({ theme }) => `${theme.spacing(6)}px auto`};
    width: 100%;
    max-width: 580px;
    border-top: ${({ theme }) =>
      `${theme.spacing(0.25)}px solid ${theme.palette.primary.light}`};
    border-bottom: ${({ theme }) =>
      `${theme.spacing(0.25)}px solid ${theme.palette.primary.light}`};
    padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}px`};
    ${borderRadius};
  }
  blockquote > * {
    color: ${({ theme }) => theme.palette.primary.dark};
    padding: ${({ theme }) => `${theme.spacing(1)}px`};
    margin: 0;
    line-height: 2.1rem;
  }
  /* 
  * =WordPress Core
  */
  .alignnone {
    margin: 5px 20px 20px 0;
  }

  .aligncenter,
  div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }

  .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  a img.alignnone {
    margin: 5px 20px 20px 0;
  }

  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  }

  .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }

  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  }

  .screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
  }
`

export default WpContent
