import { graphql, useStaticQuery } from "gatsby"
import { GatsbySeo, OpenGraphImages } from "gatsby-plugin-next-seo"
import React from "react"
import { SEO as SEOProp } from "../../template/__generated__/SEO"
import { FrontEndUrlQuery } from "./__generated__/FrontEndUrlQuery"

interface IProps {
  seo: SEOProp | null | undefined
}

const SEO: React.FC<IProps> = ({ seo }) => {
  const { site } = useStaticQuery<FrontEndUrlQuery>(graphql`
    query FrontEndUrlQuery {
      site {
        siteMetadata {
          frontEndUrl
        }
      }
    }
  `)

  const title = String(seo?.opengraphTitle)
  const description = String(seo?.opengraphDescription)
  const url = `${site?.siteMetadata?.frontEndUrl}${seo?.opengraphUrl}`
  const images: OpenGraphImages[] = [
    {
      url: String(seo?.opengraphImage?.sourceUrl),
      alt: String(seo?.opengraphImage?.altText),
      width: Number(seo?.opengraphImage?.mediaDetails?.width),
      height: Number(seo?.opengraphImage?.mediaDetails?.height),
    },
  ]
  const site_name = String(seo?.opengraphSiteName)

  return (
    <GatsbySeo
      title={String(seo?.title)}
      description={String(seo?.metaDesc)}
      openGraph={{
        title,
        url,
        description,
        images,
        site_name,
      }}
    />
  )
}

export default SEO
