import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import { darken, lighten } from "@material-ui/core/styles/colorManipulator"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { Link as GatsbyLink } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import useGqlClient from "../../hooks/useGqlClient"
import { borderRadius } from "../../theme/globalStyles"
import Center from "../base/layout/center"

const Form = styled(props => <Paper {...props} component="form" />)`
  /* margin-top: ${({ theme }) => `${theme.spacing(3)}px`}; */
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) =>
    lighten(theme.palette.primary.light, 0.22)};
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(6)}px`};
  border: ${({ theme }) => `1px solid ${theme.palette.primary.dark}`};
  ${borderRadius};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 100%;
    padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(3)}px`};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
    max-width: 486px;
  }

  > * {
    margin-bottom: ${({ theme }) => `${theme.spacing(4)}px`};
    :last-child {
      margin-bottom: 0;
    }
  }

  .noshow {
    display: none !important;
  }

  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.secondary.light};
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: ${({ theme }) => theme.palette.primary.dark};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => darken(theme.palette.primary.dark, 0.25)};
  }

  .MuiButton-outlinedPrimary {
    color: ${({ theme }) => theme.palette.primary.dark};
    border-color: ${({ theme }) => theme.palette.primary.dark};
  }

  .MuiButton-outlinedPrimary:hover {
    border-color: ${({ theme }) => theme.palette.secondary.light};
    color: ${({ theme }) => theme.palette.secondary.light};
    background-color: rgba(106, 168, 99, 0.04);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
`

const Header = styled(props => <Typography {...props} component="p" />)`
  color: ${({ theme }) => theme.palette.primary.dark};
`
interface OuterWrapperProps {
  waiting: boolean
}
const OuterWrapper = styled.div<OuterWrapperProps>`
  width: 100%;
  width: max-content;
  cursor: ${({ waiting }) => (waiting ? "wait!important" : "auto")};

  * {
    cursor: ${({ waiting }) => (waiting ? "wait!important" : "auto")};
  }
`

const ContactForm: React.FC = () => {
  const { register, handleSubmit } = useForm()
  const client = useGqlClient()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [succes, setSucces] = useState(false)

  const submit = handleSubmit(async data => {
    if (!data.field) {
      setLoading(true)
      const copy = { ...data }
      delete copy.field

      const res = await client
        .SubmitContactForm({
          input: {
            clientMutationId: "submitForm",
            ...copy,
          },
        })
        .catch(err => {
          console.warn(err)
          setError(true)
        })

      if (res && res.submitContactForm?.succes) {
        setSucces(true)
      }
      setLoading(false)
    }
  })

  if (error) {
    return (
      <Form elevation={5}>
        <Header variant="h2" align="center">
          Helaas, er is iets fout gegaan.
        </Header>
        <Header variant="body1" align="center">
          Probeert u het op een later tijdstip nog eens.
        </Header>
        <Center>
          <Link component={GatsbyLink} to="/" color="secondary">
            Terug naar de home-pagina
          </Link>
        </Center>
      </Form>
    )
  }

  if (succes) {
    return (
      <Form elevation={5}>
        <Header variant="h2" align="center">
          Bedankt voor uw bericht.
        </Header>
        <Header variant="body1" align="center">
          Er wordt zo spoedig mogelijk contact met u opgenomen.
        </Header>
        <Center>
          <Link component={GatsbyLink} to="/" color="secondary">
            Terug naar de home-pagina
          </Link>
        </Center>
      </Form>
    )
  }

  return (
    <OuterWrapper waiting={loading}>
      <Form elevation={5} onSubmit={submit}>
        <Center>
          <Header variant="h2">Contact</Header>
        </Center>
        <TextField
          innerRef={register}
          id="field"
          name="field"
          className="noshow"
          disabled={loading}
        />
        <TextField
          inputRef={register}
          label="Uw naam"
          id="name"
          name="name"
          fullWidth
          required
          disabled={loading}
        />
        <TextField
          inputRef={register}
          label="Uw e-mail"
          id="email"
          name="email"
          fullWidth
          required
          disabled={loading}
        />
        <TextField
          inputRef={register}
          label="Uw nummer"
          id="number"
          name="number"
          fullWidth
          disabled={loading}
        />
        <TextField
          inputRef={register}
          label="Uw bericht"
          id="message"
          name="message"
          multiline
          rows={6}
          fullWidth
          required
          disabled={loading}
        />
        <Center>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            size="large"
            disabled={loading}
          >
            {loading ? "bezig" : "verzend"}
          </Button>
        </Center>
      </Form>
    </OuterWrapper>
  )
}

export default ContactForm
