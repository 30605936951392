import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

interface HeroWrapperProps {
  bgImg: string | null | undefined
  light: boolean | null | undefined
  opacity: number | null | undefined
}

interface IProps extends HeroWrapperProps {
  text: string | null | undefined
}

const Wrapper = styled.div`
  width: 100%;
  height: 600px;
  background-color: ${({ theme }) => theme.palette.primary.light};
`

const HeroWrapper = styled.div<HeroWrapperProps>`
  width: 100%;
  height: 600px;
  background: linear-gradient(
      ${({ light, opacity }) =>
        light
          ? "0deg, rgba(255, 255, 255, " +
            String(opacity) +
            "), rgba(255, 255, 255, " +
            String(opacity) +
            ")"
          : "0deg, rgba(0, 0, 0, " +
            String(opacity) +
            "), rgba(0, 0, 0, " +
            String(opacity) +
            ")"}
    ),
    url(${({ bgImg }) => bgImg});
  background-position: center;
  position: relative;
  color: ${({
    light,
    theme: {
      palette: {
        common: { black, white },
      },
    },
  }) => (light ? black : white)};
`

const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  div {
    transform: translateY(25%);
  }
  div > * {
    word-wrap: break-word;
    padding: 0 ${({ theme }) => theme.spacing(1)}px;
    margin: 0;
  }
  div > h1 {
    font-size: 3.6rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      font-size: 2.2rem;
    }
  }
  div > h2 {
    line-height: 1.7rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      font-size: 1.3rem;
    }
  }
`

const HeroImage: React.FC<IProps> = ({
  bgImg = "",
  light,
  text = "",
  opacity = 0.4,
}) => (
  <Wrapper>
    <HeroWrapper bgImg={bgImg} light={light} opacity={opacity}>
      <TextWrapper>
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: String(text) }}
          color="inherit"
        />
      </TextWrapper>
    </HeroWrapper>
  </Wrapper>
)

export default HeroImage
